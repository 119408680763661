<template>
  <div
    v-if="isPDP || breadcrumbs.length > 1"
    class="wrapper_breadcrumbs"
    :class="{ 'wrapper_breadcrumbs--amv': isAMV }">
    <ul
      id="breadcrumbs"
      class="breadcrumbs_list"
      itemtype="https://schema.org/BreadcrumbList"
      itemprop="child">
      <BreadcrumbItem
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="index + '-' + breadcrumb.label"
        :label="breadcrumb.label"
        :slugs="breadcrumb.slugs"
        :index="index"
        :isLast="!isPDP && index === breadcrumbs.length - 1"></BreadcrumbItem>
    </ul>
  </div>
</template>

<script>
  import BreadcrumbItem from './BreadcrumbItem.vue';
  import { mapGetters } from 'vuex';

  export default {
    name: 'Breadcrumbs',
    components: {
      BreadcrumbItem,
    },
    props: {
      breadcrumbs: {
        type: Array,
        default: () => [],
      },
      isPDP: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters('page', {
        isAMV: 'getIsAMV',
      }),
    },
  };
</script>
