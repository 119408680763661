<template>
  <li
    v-if="label"
    itemprop="itemListElement"
    itemtype="http://schema.org/ListItem"
    class="breadcrumbs_list__item"
    :class="{ 'breadcrumbs_list__item--last': isLast }">
    <a
      :title="label"
      :href="url"
      class="href_breadcrumb"
      itemprop="item">
      <span>{{ capitalize(label.toLowerCase()) }}</span>
      <meta
        itemprop="name"
        :content="label" />
      <meta
        itemprop="position"
        :content="index" />
    </a>
  </li>
</template>

<script>
  import { computed } from 'vue';
  import { mapGetter } from 'CommonUtils/store/state.js';
  import UtilsURL from 'CommonUtils/url';
  import { capitalize } from 'CommonUtils/operations/capitalize';

  export default {
    name: 'BreadcrumbItem',
    props: {
      label: {
        type: String,
        required: true,
      },
      slugs: {
        type: Array,
        required: false,
      },
      index: {
        type: Number,
        required: true,
      },
      isLast: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const baseUrl = mapGetter('page/getBaseUrl').value;
      const query = mapGetter('page/getInternals').value;

      const url = computed(() => {
        if (props.isLast) {
          return 'javascript:void(0)';
        } else {
          const url = props.slugs?.length ? `/${props.slugs.join('/')}/` : 'javascript:void(0)';
          return UtilsURL.getFullUrlWithQuery({ baseUrl, url, query });
        }
      });
      return { url };
    },
    methods: { capitalize },
  };
</script>
